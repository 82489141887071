import line3 from '../images/case-studies/3line.jpg';
import FN from '../images/case-studies/mail.png'
import prive from '../images/case-studies/prive.jpg';
import build from '../images/case-studies/build.png';
import CWG from '../images/case-studies/cwg.jpg';
import DLW from '../images/case-studies/dlw.jpg';
import TSC from '../images/case-studies/tsc.jpg';
import wf from '../images/case-studies/2-2.png';

const cstudy = [
    {
        image: line3,
        title: '3Line',

    },
    {
        image: FN,
        title: 'Freedom Network',

    },
    {
        image: CWG,
        title: 'CWG',

    },
    {
        image: DLW,
        title: 'Dual Luxury World',

    },
    {
        image: prive,
        title: 'Prive Reveaux Nigeria',

    },
    {
        image: build,
        title: 'Build Bank',

    },
    {
        image: wf,
        title: 'Welcome Finance',

    },
    {
        image: TSC,
        title: 'The Summer Curriculum',

    },
]

export default cstudy