import slide1 from '../images/work.jpg';
import slide2 from '../images/work1.jpg';
import slide3 from '../images/work2.jpg';
import { Icon } from '@iconify/react';
import camp from '../data/case-studies';
import Slider from 'react-slick';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const campaigns = camp.map((item) => {
    return (
        <div className='slider-box' key={item} data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom">
            <img src={item.image} alt="" className='img-fluid pb-5'/>
            <h3>{item.title}</h3>
            <aside>Branding and Marketing</aside>
        </div>
    )
})

const settings = {
    className: "slider variable-width",
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1024,
            settings:  {
                className: "slider",
                slidesToShow: 3,
                variableWidth: false,
            }
        },
        {
            breakpoint: 992,
            settings:  {
                className: "slider",
                slidesToShow: 2,
                variableWidth: false,
            }
        },
        {
            breakpoint: 768,
            settings:  {
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: false,
                centerPadding: '100px'
            }
        },
        {
            breakpoint: 600,
            settings:  {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                variableWidth: false,
            }
        },
    ]
};

const Homepage = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
    function handleResize() {
        setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <main>
            <Helmet>
                <title>{props.title} - Xcentrique Media</title>
            </Helmet>
            <section className="banner d-flex container-fluid">
                <div className="banner-text d-flex px-5 pt-5">
                    <h1 className="banner-title ps-xl-5 ms-xl-5 pt-5"  data-aos="fade-down" data-aos-duration="750">
                        Every brand needs a touch of eccentricity, we've got it!
                    </h1>
                    <p className="explore-tag ps-md-5" data-aos="fade-down" data-aos-duration="750">
                       {windowWidth <= 576 ? 'Scroll Down' : 'Explore' }
                    </p>
                </div>
            </section>
            <div className="black-tag d-flex">
                    <div className="col mx-xl-5" data-aos="fade-right" data-aos-duration="750">
                        <div className="tag">
                            Because we love <span>adventure...</span>
                        </div>
                    </div>
                    <div className="col" data-aos="fade-left" data-aos-duration="750">
                        <p>
                            We carry you along at every step of the process from the conception of an idea, to the final result.
                        </p>
                    </div>
            </div>
            <section className="tagline p-5">
                <div className="text-center" data-aos="fade-up" data-aos-duration="750">
                    Come travel with us
                </div>
            </section>
            <div id={`carouselExample${windowWidth <= 992 ? 'Indicators' : ''}`} className="home carousel slide" data-bs-ride='carousel'>
                <div class="carousel-indicators d-md-flex d-lg-none">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row gy-3">
                            <div className="col-lg-6" data-aos="fade-up" data-aos-duration="750">
                                <img src={slide1} className="img-fluid" alt=''/>
                            </div>
                            <div className="col-lg-6" data-aos="fade-down" data-aos-duration="750">
                                <article className='p-md-5'>
                                    <h1>We tell stories that inspire and challenge in order to birth transformation</h1>
                                    <p>We are an embodiment of creatives that tell inspiring stories that challenge your status quo.</p>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row gy-3">
                            <div className="col-lg-6" data-aos="fade-up" data-aos-duration="750">
                               <img src={slide2} className="img-fluid" alt=''/>
                            </div>
                            <div className="col-lg-6" data-aos="fade-down" data-aos-duration="750">
                                <article className='p-md-5'>
                                    <h1>We are renowned for transforming  brands with our ECCENTRIC TOUCH</h1>
                                    <p>Our full-service team of creatives go above and beyond to create intriguing campaigns, designs, communication strategy, branding and so much more</p>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row gy-3">
                                <div className="col-lg-6" data-aos="fade-up" data-aos-duration="750">
                                <img src={slide3} className="img-fluid" alt=''/>
                                </div>
                                <div className="col-lg-6" data-aos="fade-down" data-aos-duration="750">
                                    <article className='p-md-5'>
                                        <h1>Strategic Communications and Marketing</h1>
                                        <p>Helping brands and businesses connect with their customers in the simplest yet most memorable ways is the core of our expertise.</p>
                                    </article>
                                </div>
                            </div>
                    </div>
                </div>
                <button className="carousel-control-prev d-none d-lg-block" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next d-none d-lg-block" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <section className="portfolio">
                <div className="container">
                    <div className="services">
                         <div data-aos="fade-up" data-aos-duration="750">
                             <Icon icon="mdi:bullseye" />
                            <h1>Strategy</h1>
                        </div>
                        <div data-aos="fade-down" data-aos-duration="750">
                            <Icon icon="mdi-light:image"/>
                            <h1>Content</h1>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="750">
                            <Icon icon="mdi:cube-outline" />
                            <h1>Creativity</h1>
                        </div>
                        <div data-aos="fade-down" data-aos-duration="750">
                            <Icon icon="mdi:filmstrip" />
                            <h1>Media</h1>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="750">
                            <Icon icon="mdi:bullseye" />
                            <h1>Growth</h1>
                        </div>
                    </div>
                    <article className='py-sm-2 p-lg-5' data-aos="fade-up" data-aos-duration="750" data-aos-anchor-placement="center-bottom">We dazzle our clients the very first time, and every time they come in contact with us.</article>
                </div>    
            </section>
            <div className="campaigns container-fluid p-5">
                <h6 className='pb-5' data-aos="fade-right" data-aos-duration="750">Our campaigns</h6>
                <Slider {...settings}>
                    {campaigns}
                </Slider>                   
            </div>
        </main>
    )
}

export default Homepage
