import { useLocation } from "react-router-dom"
import { Icon } from "@iconify/react"
import { useState } from "react"
import navlogo from '../images/nav-logo.png'

const Navbar = () => {
    const location = useLocation()
    const color = location.pathname.startsWith('/portfolio/') ? 'black' : 'white'
    const [icon, setIcon] = useState('octicon:three-bars-16');

    const handleMouseEnter = () => {
      setIcon('fa6-solid:bars-staggered');
    };
  
    const handleMouseLeave = () => {
      setIcon('octicon:three-bars-16');
    };

    return (
        <nav class={`navbar ${location.pathname.startsWith('/portfolio') ? '' : 'end-0 position-absolute'}`}>
            {location.pathname.startsWith('/portfolio') ? <a href="/">
                <img src={navlogo} alt="" className="navbar-brand ps-4 ps-md-5" />
            </a> : ''}
            <div class={`navbar-menu d-flex ${location.pathname.startsWith('/portfolio') ? '' : 'mt-2'}`} role="search">
                <a href="/contact-us"><button className="nav-button mt-1 mt-md-2">Contact Us</button></a>
                <button class="navbar-toggler pe-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                    <span className="navbar-toggler-icon">
                        <Icon icon={icon} color={color}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    </span>
                </button>
                <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Navigation</h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li class="nav-item" data-aos="fade-up" data-aos-duration="750">
                                <a class="nav-link" aria-current="page" href="/">Home</a>
                            </li>
                            <li class="nav-item" data-aos="fade-up" data-aos-duration="750">
                                <a class="nav-link" href="/about-us">About</a>
                            </li>
                            <li class="nav-item" data-aos="fade-up" data-aos-duration="750">
                                <a class="nav-link" href="/case-studies">Case Studies</a>
                            </li>
                            <li class="nav-item" data-aos="fade-up" data-aos-duration="750">
                                <a class="nav-link" href="/contact-us">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar