import { Helmet } from "react-helmet"
import camp from "../data/campaigns"

export default function Showcase (props) {   
    const partners = camp.map((item, index) => {
        return (
            <a href={`/portfolio/${item.name.toLowerCase().replace(/\s/g, '-')}`} className='grid-box flex-column' data-aos-duration="750" 
            key={index} data-aos="fade-down">
                <div className="grid-wrapper">
                    <div className="grid-content">
                        <aside className="pb-3">Branding and Marketing</aside>
                        <h1 className="pt-5">{item.name}</h1>
                        <a href={`/portfolio/${item.name.toLowerCase().replace(/\s/g, '-')}`}>
                            <button className="btn-content mt-5">View More</button>
                        </a>
                    </div>
                    <div className="hover-overlay">
                        <img src={item.image} alt={item.title} className="img-fluid" />
                    </div>
                </div>
            </a>
        )
    })

    return (
        <section className="showcase">
            <Helmet>
                <title>{props.title} - Xcentrique Media</title>
            </Helmet>
            <div className="banner d-flex container-fluid">
                <div className="banner-text d-flex px-5 pt-5">
                    <h1 className="banner-title">
                        Case Studies
                    </h1>
                </div>
            </div>
            <div className="case-study container py-5">
                <div className="row pb-5">
                    <div className="col-lg-6" data-aos="fade-left" data-aos-duration="750">
                        <h1>We choose a different → starting point</h1>
                    </div>
                    <div className="col-lg-6 p-5" data-aos="fade-right" data-aos-duration="750">
                        <p>Every project is a chance to try something new. Look at something with a fresh perspective. Do something for the first time.</p>
                    </div>
                </div>
            </div>
            <div className="photo-grid pt-5 mb-5">
                <div className="container-fluid">
                    <div className="grid ms-lg-5">
                        {partners}
                    </div>
                </div>
            </div>
        </section>
    )
}