import line3 from '../images/logos/3Line Logo-04(1).png';
import FN from '../images/logos/Freedom Network Logo-01.png';
import CWG from '../images/logos/CWG-Logo.png';
import DLW from '../images/logos/DLW-Logo.png';
import IJ from '../images/logos/Assets-02.png';
import BB from '../images/logos/Build Bank Logo-05.png';
import Visum from '../images/logos/Visum Logo-06.png';
import WC from '../images/logos/WC_Logo Icon.png';

const clients = [
    {
        image: line3,
        name: '3Line'
    },
    {
        image: FN,
        name: 'Freedom Network'
    },
    {
        image: CWG,
        name: 'CWG'
    },
    {
        image: DLW,
        name: 'Dual Luxury World'
    },
    {
        image: IJ,
        name: ' IsiJayne'
    },
    {
        image: BB,
        name: 'Build Bank'
    },
    {
        image: Visum,
        name: 'Visum'
    },
    {
        image: WC,
        name: 'Welcome Finance'
    }
]

export default clients