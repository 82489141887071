import welcome from '../images/case-studies/2-2.png'
import { Line1, Line2, Line3, Line4, Line5, Line6, Line7, Line8, Line9, Line10, Line11, Line12 } from '../components/case-study/pages';
import cwg1 from '../images/case-studies/cwg-1.jpg'
import cwg2 from '../images/case-studies/cwg-2.jpg'
import dlw1 from '../images/case-studies/dlw-1.jpg'
import dlw2 from '../images/case-studies/dlw-2.jpg'
import prive2 from '../images/case-studies/prive2.jpg'
import build from '../images/case-studies/build.png'


const camp = [
    {name: 'CWG 30th Anniversary', link: <Line1/>, image: cwg2},
    {name: 'Prive Reveaux', link: <Line2/>, image: prive2},
    {name: 'Texcellence Conference', link: <Line3/>, image: cwg1},
    {name: 'FMN Prize For Innovation', link: <Line4/>},
    {name: 'Redefining Luxury with Dual Luxury World', link: <Line5/>, image: dlw1},
    {name: 'Global Freedom with Dual Luxury World', link: <Line6/>, image: dlw2},
    {name: 'Choose to Challenge', link: <Line7/>},
    {name: '30 Years in 30 Minutes', link: <Line8/>},
    {name: 'Delta Fresh', link: <Line12/>},
    {name: 'Build Bank', link: <Line10/>, image: build},
    {name: 'Welcome Finance', image: welcome, link: <Line11/>},
    {name: 'Isi Jayne', link: <Line9/>}
]

export default camp