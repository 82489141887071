import Layout from '../../layouts/layout';
import bb from '../../images/case-studies/Brand Profiles-01.png'
import welcome from '../../images/case-studies/Brand Profiles-02.png'
import isij from '../../images/case-studies/Brand Profiles-03.png'

export function Line1 ()  {
    return (
        <Layout company_name='CWG 30th Anniversary' 
        what='A creative concept development for the CWG 30th Anniversary Celebration' date='September 2022'
        video="https://www.youtube.com/embed/Op7z8fcZqxc"/>
    )}

export function Line2 () {
    return (
        <Layout company_name='Prive Reveaux'
        what='Extensive Digital Marketing campaign to promote international sunglasses brand' date='July 2018'
        video="https://www.youtube.com/embed/piqSgp3xsTA"/>
    )}

export  function Line3 ()  {
    return (
        <Layout company_name='Texcellence Conference' 
        what='Creative Concept Development for FSi C-level executive conference' date='September 2022'
        video="https://www.youtube.com/embed/zTYOEztQ_OQ" />
    )}

export  function Line4 ()  {
    return (
        <Layout company_name='FMN Prize For Innovation' date='September 2021'
        what='Creative Concept development and digital strategy for social development initiative'
        video="https://www.youtube.com/embed/2OtO6R8pfhI"/>
    )}

export function Line5 () {
    return (
        <Layout company_name='Redefining Luxury with Dual Luxury World' date='November 2022'
        what="TVC campaign in partnership with the company's brand ambassador"
        video="https://www.youtube.com/embed/traVlHxt0fM"/>
    )}

export function Line9 ()  {
    return (
        <Layout company_name='Isi-Jayne' 
        what="Brand Strategy and Identity Development"  date="August 2022"
        image={isij}/>
    )}

export  function Line6 ()  {
    return (
        <Layout company_name='Global Freedom with Dual Luxury World' date="June 2022"
        what="Brand Ambassador unveil campaign"
        video="https://www.youtube.com/embed/v7ehl6kcMx4"/>
    )}

export  function Line7 ()  {
    return (
        <Layout company_name='Choose to Challenge' 
        what="International Women's Day DVC in partnership with CWG" date="March 2021"
        video="https://www.youtube.com/embed/Op7z8fcZqxc" />
    )}

export  function Line8 ()  {
    return (
        <Layout company_name='30 Years in 30 Minutes' what="30 minutes documentary executed to creatively project the strategic intent of CWG" date="September 2022"
        video = "https://www.youtube.com/embed/gBOxaKoezBA"/>
    )}

export function Line10 () {
    return (
        <Layout company_name='Build Bank' 
        what="Brand Strategy and Identity Development"  date="March 2022"
        image={bb} />
    )}

export function Line11 () {
    return (
        <Layout
            company_name='Welcome Finance' 
            what="Brand Strategy and Identity Development"  date="October 2022"
            image={welcome}/>
    )}

export  function Line12 ()  {
    return (
        <Layout
            company_name='DELTA FRESH' 
            what="TVC Campaign to unveil new delta soap variant" date="July 2019"
            video="https://www.youtube.com/embed/5uvRHRXkrs0" />
    )}
