import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from './components/Footer';
import Homepage from './components/Homepage';
import About from './components/About';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import Showcase from './components/Showcase';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import Loading from './layouts/loading';
import camp from './data/campaigns';

AOS.init()

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    document.body.setAttribute("style", "background-color: black;");
    setTimeout(() => {
      setIsLoading(false);
      document.body.removeAttribute("style");
    }, 2500);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const partners = camp.map((item, index) => {
    return (
      <Route path={`/portfolio/${item.name.toLowerCase().replace(/\s/g, '-')}`}  element={item.link} key={index} exact/>
    )
  })

  return (
    <>
     <Router basename='/'>
       <Navbar />
        <Routes>
          <Route path="/" element={<Homepage title='Home'/>} exact />
          <Route path="/about-us" element={<About title='About Us'/>} exact />
          <Route path="/contact-us" element={<Contact title='Contact Us'/>} exact />
          <Route path="/case-studies" element={<Showcase title='Case Studies'/>} exact />
          {partners}
        </Routes>
        <Footer />
        <button
        className={`btn-top ${isVisible ? "visible" : ""}`}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
          <Icon icon='material-symbols:keyboard-arrow-up'/>
        </button>
     </Router>
    </>
  );
}

export default App;
