const Map = () => {

  return (
    <div className="mapouter">
        <div className="embed-responsive embed-responsive-16by9">
            <iframe title="Example"
            className="embed-responsive-item"
            id="gmap_canvas" src="https://maps.google.com/maps?q=2,%20Austin%20Agbolahor%20Close,%20Magodo%20St,%20Magodo%20100248,%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed" 
            frameborder="0" marginheight="0" marginwidth="0"></iframe>
        </div>
    </div>
  );
};

export default Map;
