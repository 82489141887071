import logo from '../images/Xcentrique Media logo.png';
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation()

    return (
       <footer>
            {location.pathname === '/contact-us' ? '' :
            <div className="contact-tagline" data-aos="fade-up" data-aos-duration="750">
                <p>Need help with your next project</p>
                <h1>Talk to us</h1>
            </div>}
                <div className="footer-section">
                    <div className="row gx-md-0">
                        <div className="col-6 col-md-4">
                            <img src={logo} alt="Logo" className="img-fluid" />
                        </div>
                        <div className="col-6 col-md-4 py-md-5">
                            <ul className="footer-links">
                                <a href="/">
                                    <li>Home</li>
                                </a>
                                <a href="/case-studies">
                                    <li>Case Studies</li>
                                </a>
                                <a href="/about-us">
                                    <li>About Us</li>
                                </a>
                                <a href="/contact-us">
                                    <li>Contact Us</li>
                                </a>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 py-5">
                            <ul className='contact-section'>
                            Address
                                <li className='py-4'>
                                2, Austin Agbolahor Close, Magodo, Lagos State</li>

                                <li>+234 (0) 705 667 8537</li>
                                <li>info@xcentriquemedia.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bottom-footer d-flex">
                        <div className="fbottom px-4 py-sm-3 py-md-5">
                            <div className="social-icons my-sm-5">
                                <ul className='d-flex'>
                                    <li>
                                        <a href="https://www.instagram.com/xcentriquemedia/?hl=en" target='_blank' rel="noreferrer">
                                            <Icon icon="mdi:instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/XcentriqueMedia" target='_blank' rel="noreferrer">
                                            <Icon icon="mdi:twitter" />
                                        </a> 
                                    </li>
                                    <li>
                                        <a href="https://web.facebook.com/profile.php?id=100070096093790" target='_blank' rel="noreferrer">
                                            <Icon icon="uil:facebook-f" />
                                        </a>
                                    </li>
                                    <li> 
                                        <a href="https://www.linkedin.com/company/xcentrique-media/" target='_blank' rel="noreferrer">
                                            <Icon icon="mdi:linkedin" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="dev text-center py-3">
                2023 &copy; Xcentrique Media
            </div>
       </footer>
    )
}

export default Footer