import { Helmet } from "react-helmet";
import Map from "./Map";
import { useState } from "react";

export default function Contact (props) {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const emailBody = `Name: ${name}%0D%0ACompany: ${company}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
        window.location.href = `info@xcentriquemedia.com?subject=New message from ${name} at ${company}&body=${emailBody}`;
        setName('');
        setCompany('');
        setEmail('');
        setMessage('');
    };
  
    return (
        <section className="contact-section">
            <Helmet>
                <title>{props.title} - Xcentrique Media</title>
            </Helmet>
             <div className="banner d-flex container-fluid">
                <div className="banner-text d-flex px-5 pt-5">
                    <h1 className="banner-title">
                        Contact Us
                    </h1>
                </div>
            </div>
            <div className="contact-form">
                <div className="container">
                    <h1 className="p-5">Start the conversation</h1>
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-left" data-aos-duration="750">
                            Contact Info:
                            <ul>
                                <li><span>Address:</span> 2, Austin Agbolahor Close, Magodo. Lagos state</li>
                                <li><span>Phone:</span> +234 705 667 8537</li>
                                <li><span>Email:</span> info@xcentriquemedia.com</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={handleSubmit} method="post" data-aos="fade-right" data-aos-duration="750">
                                <div className="form-group" data-aos="fade-down" data-aos-duration="750">
                                    <input type="text" name="f-name" id="" className="form-control shadow-none" placeholder="Name" 
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required/>
                                </div>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="750">
                                    <input type="text" name="c-name" id="" className="form-control" placeholder="Company"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    required/>
                                </div>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="750">
                                    <input type="email" name="mail" id="" className="form-control" placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required/>
                                </div>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="750">
                                    <input type="text" name="msg" id="" className="form-control" placeholder="Message" 
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required/>
                                </div>
                                <div className="py-3">By submitting this form, you are agreeing to our <em>privacy policy</em>.</div>
                                <div className="form-check py-3">
                                    <input className="form-check-input" type="checkbox" required/>
                                    <label className="form-check-label">I agree</label>
                                </div>
                                <button className="btn-contact" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="address container-fluid mt-5">
                <div className="row">
                    <div className="col-lg-5 p-xl-5">
                        <ul className='contact-section p-xl-5 py-5' data-aos="fade-up" data-aos-duration="750">
                            Address
                            <li className='py-4'>
                            2, Austin Agbolahor Close, Magodo, Lagos State
                            </li>
                            <li>+234 (0) 705 667 8537</li>
                            <li>info@xcentriquemedia.com</li>
                        </ul>
                    </div>
                    <div className="col-lg-7 map p-0" data-aos="fade-down" data-aos-duration="750">
                        <Map/>
                    </div>
                </div>
            </div>
        </section>
    )
}