import { useState } from 'react';
import about from '../images/image1.jpg';
import { Icon } from '@iconify/react';
import clients from '../data/clients';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

const About = (props) => {
    const [isVideoVisible, setIsVideoVisible] = useState(false); 

    const partners = clients.map((item) => {
        return (
            <div className="partner-box" key={item} data-aos="fade-up" data-aos-duration="750">
                <img src={item.image} alt={item.name} className="img-fluid" />
                <div className="content">
                    <div className="text">{item.name}</div>
                </div>
            </div>
        )
    })

    const showYoutubeVideo = ()  =>{
        setIsVideoVisible(true)
    }

    const hideYoutubeVideo = () => {
        setIsVideoVisible(false)
    }

    const YouTube = (
        <div className="youtube-popwrap"
            onClick={hideYoutubeVideo}>
            <div className="youtube-popcontent">
                <div className="embed-responsive embed-responsive-16by9">
                    <ReactPlayer 
                        muted="true"
                        playing="true"
                        controls="true"
                    url="https://www.youtube.com/watch?v=yJPpmzqWOyo" allowfullscreen/>
                </div>
            </div>
        </div>
    )

    return (
        <section className="about-section">
            <Helmet>
                <title>{props.title} - Xcentrique Media</title>
            </Helmet>
            <div className="banner d-flex container-fluid">
                <div className="banner-text d-flex px-5 pt-5">
                    <h1 className="banner-title">
                        About Us
                    </h1>
                </div>
            </div>
            <div className="about-us container-fluid">
                <div className="row">
                    <div className="col-lg-6 py-5" id='about'>
                        <h3>About Us</h3>
                        <h2>We are conversation starters</h2>
                        <ul>
                            <li data-aos="fade-up" data-aos-duration="750">
                                <h6>
                                    <span>1</span>
                                    Our Mission
                                </h6>
                                <p>To constantly inspire and innovate while offering revolutionary marketing communications services through
                                best thinking and strategic storytelling methods.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="750">
                                <h6>
                                    <span>2</span>
                                    Our Vision
                                </h6>
                                <p>To be the leading brand transformation company in Africa</p>
                            </li>            
                            <li data-aos="fade-up" data-aos-duration="750">
                                <h6>
                                    <span>3</span>
                                    Our Eccentricity
                                </h6>
                                <p>We are daring. We strive to create a distance between us and what can be called ‘The Norm’. While we like to meet your expectations, we aim to beat your imaginations. We do not believe in impossibilities. We build brands that soars above all limitations.</p>
                            </li>
                        </ul>
                        <a href="/contact"><button className='btn-contact' type="submit">Start a Project</button></a>
                    </div>
                    <div className="col-lg-6">
                        <img src={about} alt="" className="img-fluid" />
                    </div>   
                </div>
            </div>    
            <div className="section strengths">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-6 d-flex flex-column align-self-stretch" data-aos="fade-down" data-aos-duration="750">
                            <article>
                                <h2 className="mb-4">Our Strengths</h2>
                                <p>We are an embodiment of creatives that tell compelling stories to inspire and challenge.</p>
                            </article>
                        </div>
                        <div className="col-md-3 d-flex align-self-stretch" data-aos="fade-right" data-aos-duration="750">
                            <div className="strength">
                                <div className="d-flex justify-content-end">
                                    <Icon icon="mdi-light:bullhorn" />
                                </div>
                                <div className="heading mt-md-5 mb-3">
                                    Campaign Planning
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex align-self-stretch" data-aos="fade-right" data-aos-duration="750">
                            <div className="strength">
                                <div className="d-flex justify-content-end">
                                    <Icon icon="icon-park-outline:communication" />
                                </div>
                                <div className="heading mt-md-5 mb-3">
                                    Communication Management
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex" data-aos="fade-up" data-aos-duration="750">
                            <div className="strength">
                                <div className="d-flex justify-content-end">
                                    <Icon icon="mdi:film-open-outline" />
                                </div>
                                <div className="heading mt-md-5 mb-3">
                                    Production
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex" data-aos="fade-up" data-aos-duration="750">
                            <div className="strength">
                                <div className="d-flex justify-content-end">
                                    <Icon icon="la:mail-bulk" />
                                </div>
                                <div className="heading mt-md-5 mb-3">
                                Digital & Social Media Strategy
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex" data-aos="fade-up" data-aos-duration="750">
                            <div className="strength">
                                <div className="d-flex justify-content-end">
                                    <Icon icon="fa6-regular:chess-knight" />
                                </div>
                                <div className="heading mt-md-5 mb-3">
                                    Brand Strategy
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex" data-aos="fade-up" data-aos-duration="750">
                            <div className="strength">
                                <div className="d-flex justify-content-end">
                                    <Icon icon="fluent:arrow-growth-24-filled" />
                                </div>
                                <div className="heading mt-md-5 mb-3">
                                    Growth Management
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section container-fluid video-banner px-5 mb-5">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up" data-aos-duration="750">
                        <div className="play-button mb-5" onClick={showYoutubeVideo}>
                            <Icon icon="material-symbols:play-arrow-rounded" />
                        </div>
                        <h1 className='pb-5'>Xcentrique constantly explores unexpected paths to position companies to reach unimaginable heights.</h1>
                    </div>
                    <div className="col-lg-5 offset-lg-1 values-section" data-aos="fade-down" data-aos-duration="750">
                        <h1>Our Values</h1>
                        <h3 className="py-4 px-2">We are <b>M.A.D.E</b> for you:</h3>
                        <div id="carouselExampleIndicators" class="carousel slide" data-bs-interval="2000" data-bs-ride="carousel" data-bs-wrap='true'>
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            </div>
                            <div class="carousel-inner values">
                                <div className="carousel-item active">
                                    <h1>M</h1>
                                    <p>Move With The Cheese</p>
                                </div>
                                <div className="carousel-item">
                                    <h1>A</h1>
                                    <p>Advancedly equipped</p>
                                </div>
                                <div className="carousel-item">
                                    <h1>D</h1>
                                    <p>Dynamic in nature</p>
                                </div>
                                <div className="carousel-item">
                                    <h1>E</h1>
                                    <p>Extremely Enthusiastic</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grey section">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="tag" id='tag2'>
                                Who we are?
                               </div>
                            </div>
                        <div className="col-lg-8">
                            We embed ourselves into our clients businesses to tell stories through their lenses.
                            Our goal is to dazzle our clients the first time they come in contact with us and every time.
                            <br/><br/>
                            We want our partners to see a piece of themselves in us, then, count on us to be their personal tour guide for navigating their way through the communications world.
                        </div>
                    </div>
                </div>
            </div>
            <div className="eccentric">
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-duration="750">
                            <h1>The Eccentric Bond</h1>
                        </div>
                        <div className="col-lg-6">
                            <ul>
                                <li data-aos="fade-up" data-aos-duration="750">We inspire </li>
                                <li data-aos="fade-up" data-aos-duration="750">We take a leap of faith</li>
                                <li data-aos="fade-up" data-aos-duration="750">We face challenges head on </li>
                                <li data-aos="fade-up" data-aos-duration="750">And constantly run towards what terrifies us </li>
                                <li data-aos="fade-up" data-aos-duration="750">We lead trends </li>
                                <li data-aos="fade-up" data-aos-duration="750">We transform brands</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="partners py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 ft-emp" data-aos="fade-left" data-aos-duration="750">
                            <h5>Clients</h5>
                            <h1>Meet Our Partners.</h1>
                        </div>
                        <div className="col-lg-8">
                            <div className="brands">
                                {partners}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isVideoVisible ? YouTube : ''}
        </section>
    )
}

export default About