import image1 from '../images/blacklight-boby-painting.jpg';
import { Helmet } from 'react-helmet'
export default function Layout (props) {
    const banner = (
        props.video ? (
          <div className="embed-responsive embed-responsive-21by9">
            <iframe className="embed-responsive-item" src={props.video} title={props.company_name}></iframe>
          </div>
        ) : (
          props.image ? (
            <img src={props.image} alt={props.company_name} className='img-fluid' style={{transform: "scale(1.2)"}}/>
          ) : (
            <img src={image1} className="img-fluid" alt="Default" />
          )
        )
      );
      

    return (
        <section className="case">
            <Helmet>
                <title>{props.company_name} - Xcentrique Media</title>
            </Helmet>
            <div className="description py-5">
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-7" data-aos="fade-up" data-aos-duration="750">
                            <h1>{props.company_name}</h1>
                        </div>
                        <div className="col-lg-5 desc">
                            <ul className="d-flex">
                                <li data-aos="fade-up" data-aos-duration="750">WHO</li>
                                <li data-aos="fade-up" data-aos-duration="750">{props.company_name}</li>
                            </ul>
                            <ul className="d-flex">
                                <li data-aos="fade-up" data-aos-duration="750">WHAT</li>
                                <li data-aos="fade-up" data-aos-duration="750">{props.what}</li>
                            </ul>
                            <ul className="d-flex">
                                <li data-aos="fade-up" data-aos-duration="750">WHEN</li>
                                <li data-aos="fade-up" data-aos-duration="750">{props.date}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="poster p-5" data-aos="fade-up" data-aos-duration="750">
                {banner}
            </div>
            <div className="container">
                {/* <div className="row p-5">
                    <div className="col-lg-4 subheading" data-aos="fade-left" data-aos-duration="750">
                        <p>
                            <span>1.</span>
                            The Challenge
                        </p>
                    </div>
                    <div className="col-lg-8" data-aos="fade-right" data-aos-duration="750">
                        <p>{props.challenge}</p>
                    </div>
                </div>
                
                {props.approach ? <div className="row p-5">
                    <div className="col-lg-4 subheading" data-aos="fade-left" data-aos-duration="750">
                        <p>
                            <span>2.</span>
                            Our Approach
                        </p>
                    </div>
                    <div className="col-lg-8" data-aos="fade-right" data-aos-duration="750">
                        <p>{props.approach}</p>
                    </div>
                </div>: ''}
                
                <div className="row p-5">
                    <div className="col-lg-4 subheading">
                        <p>
                            <span>3.</span>
                            The Result
                        </p>
                    </div>
                    <div className="col-lg-8">
                        <p>{props.result}</p>
                    </div>
                </div> */}
            </div>
            <div className="container py-5 text-center">
                <div className="py-5">
                    <a href="/case-studies"><button className='btn-back'>Back to case study list</button></a>
                </div>
            </div>
        </section>
    )
}
